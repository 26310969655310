<main *ngIf="!(activeAcctSelected$ | async)">
	Please provide an account code in the <i>active</i> parameter in the URL.
</main>

<div *ngIf="(activeAcctSelected$ | async)" class="app-wrapper" fxLayout="column">
	<div fxLayout="row">
		<zui-sidenav
				#sidenav
					 [sidenavParams]="sidenavConfig"
					 [header]="sidenavHeader"
				     [footer]="sidenavFooter"
					 [menu]="sidenavMenu"
					 (emitSelectedCompany)="setCompany($event)">
			<div class="sidenav-container app-bg-color" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="3rem">
				<div fxLayout="column" fxLayoutAlign="start stretch" class="ghost-background">
					<zui-sidenav-header class="header-bar-sidenav"
										[title]="title"
										(menuToggled)="onSideNavMobileMenuButtonToggled($event)"></zui-sidenav-header>
					<z-side-bar [opened]="contextualHelpOpen" (closed)="turnOffSidebarState()" position="right">
						<mat-spinner mode="indeterminate"
							*ngIf="contextualHelpLoading"
							color="accent"
							diameter="150"
							[style]="{
								height:'150px',
								width:'150px',
								position: 'absolute',
								top:'50%',
								left: '50%',
								transform:
								'translate(-50%, -50%)'
							}"
							strokeWidth="4">
						</mat-spinner>
						<section [innerHTML]="contextualHelpHTML"></section>
					</z-side-bar>
					<header-bar></header-bar>
					<main *ngIf="(activeAcctSelected$ | async)" class="ghost-background">
						<info-alert
								[minutesSinceRefresh]="minutesSinceRefresh"
								[hideRefreshBar]="hideRefreshBar"
						></info-alert>
						<router-outlet></router-outlet>
					</main>
				</div>
			</div>
		</zui-sidenav>
		<div class="content-container">
			<zui-footer-component></zui-footer-component>
		</div>
	</div>
</div>

