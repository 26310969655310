<div class="z-header">
<!--	<div class="header-contain">-->
<!--		<div class="header-logo-container">-->
<!--			<a routerLink="/" queryParamsHandling="merge">-->
<!--				<span class="header-logo-text">Hours of Service</span>-->
<!--			</a>-->
<!--		</div>-->
<!--		<div>-->
<!--			&lt;!&ndash;Navigation&ndash;&gt;-->
<!--		</div>-->
<!--		<div class="account">-->
<!--			<div>-->
<!--				<div class="user-select" [class.expanded]="expanded" [matMenuTriggerFor]="userMenu"-->
<!--					(menuOpened)="expanded=true" (menuClosed)="expanded=false"-->
<!--					 fxLayoutAlign="space-between center"-->
<!--					 fxLayoutGap="0.5em"-->
<!--					 fxFill>-->
<!--					<div>-->
<!--						<div class="user-name">{{userName}}</div>-->
<!--						<div class="company-name-color">{{companyName}}</div>-->
<!--					</div>-->
<!--					<div></div>-->
<!--					<div>-->
<!--						<mat-icon class="user-chevron-button" [@rotateChevron]="expanded">expand_more</mat-icon>-->
<!--					</div>-->
<!--				</div>-->
<!--				<mat-menu #userMenu="matMenu" class="user-menu app-menu" xPosition="before" yPosition="below" [overlapTrigger]="false">-->
<!--					<div class="navigation-menu-container" *ngFor="let item of headerItems">-->
<!--						<button mat-flat-button class="navigation-button">-->
<!--							<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" class="menu-row"-->
<!--								(click)="goToRoute(item.routerLink)">-->
<!--								<div *ngIf="item.label === 'Logout'" class="logout-icon"></div>-->
<!--								<div class="navigation-label">{{item.label}}</div>-->
<!--							</div>-->
<!--						</button>-->
<!--					</div>-->
<!--				</mat-menu>-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->
	<zui-hos-navigation [activeAccount]="account"></zui-hos-navigation>
	<dispatcher-list *ngIf="showDLComponent$ | async"></dispatcher-list>
	<location-list *ngIf="!(showDLComponent$ | async) && (showLocationFilter$ | async)"></location-list>
</div>
