import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import {environment} from "../../../environments/environment";
export const sidenavGlobalConfig: SidenavParams = {
    mobileOpened: true,
    footerOffset: true,
    expanded: true,
    suppressChangeCompanyDialog: false,
    displayToAnonymousUser: false,
    hideChangeCompanyButton: true //Todo: check if compliance needs to set up the change company dialog

    /* options:
      childIndentValue?: number;
      displayToAnonymousUser?: boolean;
      expanded?: boolean;
      footerOffset?: boolean;
      hideChangeCompanyButton?: boolean;
      hideCompanyNameInCompactMode?: boolean;
      hideLogoutButton?: boolean;
      lockExpansion?: boolean;
      mobileOpened?: boolean;
      mobileViewWidthBreakpoint?: number;
      useDefaultHelpItem?: boolean;
      disableBackdropClose?: boolean;
      suppressChangeCompanyDialog?: boolean;
    */
};

export const sidenavHeaderConfig = {
    displayName: true, // if we want to display name of the user
    avatarImageUrl: null, // if we want to show a specific avatar picture e.g. user's avatar
    optionalSubtitle: null,
    hideMobileMenuButton: false
};

export const sidenavFooterConfig = {
    items: []
};

//POC for mobile configs
export const desktopMenu  = [
    {
        text: 'Maps',
        icon: 'map',
        appId: '0961d523-ef76-489a-8613-e0e639d5b233',
        route: 'https://maps.dev.zonarsystems.net',
        routerLink: 'https://maps.dev.zonarsystems.net',
        children: [],
        isExpanded: false,
        appFeatureKey: 'sidenav-maps',
    },
    {
        text: 'Maintenance',
        icon: 'build_circle',
        isExpanded: true,
        children: [
            {
                text: 'Maintenance Overview',
                icon: '',
                appId: 'c26417fc-3768-4f78-b96e-02770da411d9',
                route: 'https://maintenance.dev.zonarsystems.net/overview',
                routerLink: 'https://maintenance.dev.zonarsystems.net/overview',
                isExpanded: false,
                children: []
            },
            {
                text: 'Emissions Check',
                icon: '',
                appId: '7f57da4e-6f1e-4eca-b622-3ab28b78eb86',
                route: 'https://emissions-check.dev.zonarsystems.net/emissions',
                routerLink: 'https://emissions-check.dev.zonarsystems.net/emissions',
                isExpanded: false,
                children: [],
                appFeatureKey: 'sidenav-emission-check'
            },
            {
                text: 'Defects (EVIR)',
                icon: '',
                appId: '21fc7845-66c9-4862-bc71-955fda024daa',
                route: 'https://evir-web.dev.zonarsystems.net/defects',
                routerLink: 'https://evir-web.dev.zonarsystems.net/defects',
                isExpanded: false,
                children: [],
                appFeatureKey: 'sidenav-evir'
            }
        ]
    },
    {
        text: 'Administration',
        icon: 'settings',
        isExpanded: false,
        children: [
            {
                text: 'Asset List',
                icon: '',
                appId: 'b9203a2f-1a72-42ff-bcba-b7c017a1d49d',
                route: 'https://administration.dev.zonarsystems.net/company/{companyId}/assets',
                routerLink: 'https://administration.dev.zonarsystems.net/company/{companyId}/assets',
                isExpanded: false,
                children: [],
                requiredPermissions: ['self_service:read:assets'],
                appFeatureKey: 'sidenav-administration-assets'
            },
        ],
    },
    {
        text: 'Compliance',
        icon: 'verified',
        routeLevel: 0,
        isExpanded: false,
        appFeatureKey: 'sidenav-evir',
        children: [
            {
                text: 'Inspection History (EVIR)',
                icon: '',
                appId: '21fc7845-66c9-4862-bc71-955fda024daa',
                route: 'https://evir-web.dev.zonarsystems.net/inspection-list',
                routerLink: 'https://evir-web.dev.zonarsystems.net/inspection-list',
                isExpanded: false,
                routeLevel: 1,
                children: []
            },
            {
                text: 'Missing Inspections (EVIR)',
                icon: '',
                appId: '21fc7845-66c9-4862-bc71-955fda024daa',
                route: 'https://evir-web.dev.zonarsystems.net/missing-inspections',
                routerLink: 'https://evir-web.dev.zonarsystems.net/missing-inspections',
                isExpanded: false,
                routeLevel: 1,
                children: []
            },
            {
                text: 'HoS',
                icon: '',
                appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                route: environment.appUrl,
                routerLink: environment.appUrl,
                isExpanded: false,
                routeLevel: 1,
                children: [
                    // {
                    //     text: 'Overview',
                    //     icon: '',
                    //     appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                    //     route: environment.appUrl,
                    //     routerLink: environment.appUrl,
                    //     isExpanded: false,
                    //     routeLevel: 1,
                    //     children: []
                    // },
                    // {
                    //     text: 'Malfunctions',
                    //     icon: '',
                    //     appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                    //     route: environment.appUrl + `/malfunctions`,
                    //     routerLink: environment.appUrl + `/malfunctions`,
                    //     isExpanded: false,
                    //     routeLevel: 1,
                    //     children: []
                    // },
                    // {
                    //     text: 'Hos-Violations',
                    //     icon: '',
                    //     appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                    //     route: environment.appUrl + `/hos-violations`,
                    //     routerLink: environment.appUrl + `/hos-violations`,
                    //     isExpanded: false,
                    //     routeLevel: 1,
                    //     children: []
                    // },
                    // {
                    //     text: 'Availability',
                    //     icon: '',
                    //     appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                    //     route: environment.appUrl + `/availability`,
                    //     routerLink: environment.appUrl + `/availability`,
                    //     isExpanded: false,
                    //     routeLevel: 1,
                    //     children: []
                    // },
                    // {
                    //     text: 'Special Duty Statuses',
                    //     icon: '',
                    //     appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                    //     route: environment.appUrl + `/sds`,
                    //     routerLink: environment.appUrl + `/sds`,
                    //     isExpanded: false,
                    //     routeLevel: 1,
                    //     children: []
                    // },
                    // {
                    //     text: 'Driver Hours',
                    //     icon: '',
                    //     appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                    //     route: environment.appUrl + `/hours`,
                    //     routerLink: environment.appUrl + `/hours`,
                    //     isExpanded: false,
                    //     routeLevel: 1,
                    //     children: []
                    // },
                    // {
                    //     text: 'Odometer Jumps',
                    //     icon: '',
                    //     appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                    //     route: environment.appUrl + `/odometer`,
                    //     routerLink: environment.appUrl + `/odometer`,
                    //     isExpanded: false,
                    //     routeLevel: 1,
                    //     children: []
                    // },
                ]
            },
            {
                text: 'Unidentified Driving',
                icon: '',
                appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                route: environment.urls.eldx, //Todo: change this later
                routerLink: environment.urls.eldx, //Todo: change this later
                isExpanded: false,
                routeLevel: 1,
                children: []
            },
            {
                text: 'Driver Logs',
                icon: '',
                appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                route: `${environment.urls.eldx}/driver-logs/drivers`, //Todo: change this later
                routerLink: `${environment.urls.eldx}/driver-logs/drivers`, //Todo: change this later
                isExpanded: false,
                routeLevel: 1,
                children: []
            }
        ]
    }

];
export const mobileMenu  = [
    {
        text: 'Maps',
        icon: 'map',
        appId: '0961d523-ef76-489a-8613-e0e639d5b233',
        route: 'https://maps.dev.zonarsystems.net',
        routerLink: 'https://maps.dev.zonarsystems.net',
        children: [],
        isExpanded: false,
        appFeatureKey: 'sidenav-maps',
    },
    {
        text: 'Maintenance',
        icon: 'build_circle',
        isExpanded: true,
        children: [
            {
                text: 'Maintenance Overview',
                icon: '',
                appId: 'c26417fc-3768-4f78-b96e-02770da411d9',
                route: 'https://maintenance.dev.zonarsystems.net/overview',
                routerLink: 'https://maintenance.dev.zonarsystems.net/overview',
                isExpanded: false,
                children: []
            },
            {
                text: 'Emissions Check',
                icon: '',
                appId: '7f57da4e-6f1e-4eca-b622-3ab28b78eb86',
                route: 'https://emissions-check.dev.zonarsystems.net/emissions',
                routerLink: 'https://emissions-check.dev.zonarsystems.net/emissions',
                isExpanded: false,
                children: [],
                appFeatureKey: 'sidenav-emission-check'
            },
            {
                text: 'Defects (EVIR)',
                icon: '',
                appId: '21fc7845-66c9-4862-bc71-955fda024daa',
                route: 'https://evir-web.dev.zonarsystems.net/defects',
                routerLink: 'https://evir-web.dev.zonarsystems.net/defects',
                isExpanded: false,
                children: [],
                appFeatureKey: 'sidenav-evir'
            }
        ]
    },
    {
        text: 'Administration',
        icon: 'settings',
        isExpanded: false,
        children: [
            {
                text: 'Asset List',
                icon: '',
                appId: 'b9203a2f-1a72-42ff-bcba-b7c017a1d49d',
                route: 'https://administration.dev.zonarsystems.net/company/{companyId}/assets',
                routerLink: 'https://administration.dev.zonarsystems.net/company/{companyId}/assets',
                isExpanded: false,
                children: [],
                requiredPermissions: ['self_service:read:assets'],
                appFeatureKey: 'sidenav-administration-assets'
            },
        ],
    },
    {
        text: 'Compliance',
        icon: 'verified',
        routeLevel: 0,
        isExpanded: false,
        appFeatureKey: 'sidenav-evir',
        children: [
            {
                text: 'Inspection History (EVIR)',
                icon: '',
                appId: '21fc7845-66c9-4862-bc71-955fda024daa',
                route: 'https://evir-web.dev.zonarsystems.net/inspection-list',
                routerLink: 'https://evir-web.dev.zonarsystems.net/inspection-list',
                isExpanded: false,
                routeLevel: 1,
                children: []
            },
            {
                text: 'Missing Inspections (EVIR)',
                icon: '',
                appId: '21fc7845-66c9-4862-bc71-955fda024daa',
                route: 'https://evir-web.dev.zonarsystems.net/missing-inspections',
                routerLink: 'https://evir-web.dev.zonarsystems.net/missing-inspections',
                isExpanded: false,
                routeLevel: 1,
                children: []
            },
            {
                text: 'Driver Logs',
                icon: '',
                appId: '0c96e82d-da2d-4858-9169-b7729516a960',
                route: `${environment.urls.eldx}/driver-logs/drivers`,
                routerLink: `${environment.urls.eldx}/driver-logs/drivers`,
                isExpanded: false,
                routeLevel: 1,
                children: []
            }
        ]
    }

];
