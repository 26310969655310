import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router, ResolveEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import {BehaviorSubject, Subscription} from 'rxjs';
import {
	removeContextualHelp,
	getDispatchersList,
	setSelectedTimeOption,
	setTimeOptions,
	incrementRefreshMs,
	setMinutesSinceRefresh,
	refreshIntervalSet,
	getLocationsList,
	getAssetOptions,
	getDriverOptions,
	getLocationOptions,
} from './state/app/app.actions';
import { AppService } from './state/app/app.service';

import { UserService } from './components/user/user.service';
import { RootState } from './app.module';
import { appFeature, selectedEntitySelector } from './state/app/app.selectors';
import { OneMinute, TwoMinutes } from './utils/time-utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { ACTIVE_ACCOUNT_PARAM, LocalStorageService, SELECTED_ENTITY } from './local-storage.service';
import { AppRouteData } from './app.resolver';
import { SelectedEntity } from './state/app/app-types';
import * as _ from 'lodash';
import { NgxPendoService } from 'ngx-pendo';
import {PermissionsService} from '@zonar-ui/auth';
import {DataDogService} from './datadog.service';

import {
	desktopMenu, mobileMenu, sidenavFooterConfig,
	sidenavGlobalConfig,
	sidenavHeaderConfig,
} from '../app/reports/shared-report-components/sidenav.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import {SidenavComponent} from '@zonar-ui/sidenav';
import {appName} from "../environments/shared";

const PERSISTENT_PARAMETERS = ['beginDate', 'endDate', 'sdsType', 'tableMalfunctionType', 'tableStartDate', 'tableEndDate'];

@Component({
	selector: 'app-component',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('sidenav') zonarSideNav: SidenavComponent;
	sidenavConfig = sidenavGlobalConfig;
	sidenavHeader = sidenavHeaderConfig;
	sidenavMenu = desktopMenu;
	sidenavFooter= sidenavFooterConfig;
	title = appName;
	account;

	constructor(
		private store: Store<RootState>,
		private route: ActivatedRoute,
		private router: Router,
		public appService: AppService,
		public users: UserService,
		private localStorageService: LocalStorageService,
		private ngxPendoService: NgxPendoService,
		private permissionsService: PermissionsService,
		private datadogService: DataDogService,
		private cd: ChangeDetectorRef,
		private deviceService: DeviceDetectorService
	) {
		const device = this.deviceService.getDeviceInfo();
		if (device.deviceType === 'mobile') {
			console.log('mobile');
			this.sidenavMenu = mobileMenu;
		} else {
			console.log('desktop');
			this.sidenavMenu = desktopMenu;
		}
	}

	public activeAcctSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public contextualHelpLoading: boolean;

	public contextualHelpOpen: boolean;

	public contextualHelpHTML: Object;

	public isProd: boolean;

	public minutesSinceRefresh = 0;

	private isFirstLoad = true;

	public hideRefreshBar = false;
	
	appRouteData: AppRouteData;


	ngOnInit() {
		this.datadogService.startSessionReplayRecording();
		this.appRouteData = this.route.snapshot.data.data as AppRouteData;
		console.log('App component - App route data', this.appRouteData);
		// If active account not selected just return it don't process anything until the active account is selected
		if (!this.appRouteData.activeAccountSelected) {
			return;
		}
		// if (this.appRouteData.activeAccountSelected && !this.appRouteData['selectedCompany']) {
		// 	console.log('sidenav', this.zonarSideNav);
		// 	// this.zonarSideNav.setSelectedCompany({
		// 	// 	value: activeAccount,
		// 	// 	title: activeAccount,
		// 	// });
		// }
		try {
			const user = this.appRouteData.user;
			const activeAccount = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);
			this.account = activeAccount;
			// console.log('active account', activeAccount);
			const isAdmin = (this.appService.isZonarAdmin(user) || this.appService.isCustomerAdmin(activeAccount));
			this.permissionsService.getCurrentCompanyContext().subscribe((company) => {
				console.log('....app component company', company);
				company = !!company ? company : {};
				this.pendoInitialize(user, isAdmin, activeAccount, company);
			});
			if (this.appRouteData.dispatcher) {
				//If the user is a non-zonar user, then allow the company button to be shown in the sidenav
				this.sidenavConfig.hideChangeCompanyButton = false;
			}
		} catch (err) {
			console.error('Pendo initialization error', err);
		}
		this.activeAcctSelected$.next(true);
		this.subscribeToAppFeature();
		this.route.queryParams.subscribe((params: { days: string }) => {
			if (params.days) { this.store.dispatch(setSelectedTimeOption(params)); }
		});
		this.store.dispatch(setTimeOptions());

		this.store.select(selectedEntitySelector).subscribe((ent: SelectedEntity) => {
			const previousEnt = this.localStorageService.get(SELECTED_ENTITY);
			if (ent.entityType && ent.value) {
				this.localStorageService.set(SELECTED_ENTITY, ent);
				// CAUTION: Do not remove this check, otherwise the page refreshes indefinitely.
				if (!previousEnt || (previousEnt.entityType === ent.entityType && previousEnt.value !== ent.value)
					|| (previousEnt.entityType !== ent.entityType)) {
					window.history.pushState(null, null, this.filterUrlParams(PERSISTENT_PARAMETERS, window));
					window.location.reload();
				}
			}
		});
	}

	setCompany(selection: { value: string; title: string }): void {
		//Todo: check if we are allowing to set company in CI and actively change the query params with legacy code or activeAccount
		console.log('set company in app component', selection);
		if (this.appRouteData.selectedCompany && this.appRouteData.selectedCompany.id ===
			selection.value) {
			return;
		}
		// this.appService.setCompany(selection.value, this.appRouteData.userProfiles[0])
		// 	.pipe(map(company => {
		// 			this.activeAccount.next(true);
		// 			// Some times returned company is empty. So, set the company from selection if it's empty.
		// 			if (!company || !company.id) {
		// 				if (!company) {
		// 					this.appRouteData.selectedCompany = this.getCompanyObject(selection);
		// 				} else {
		// 					this.appRouteData.selectedCompany = company;
		// 					this.appRouteData.selectedCompany = company as any;
		// 				}
		// 				this.initializePendo();
		// 			}),
		// 		catchError((err) => {
		// 			console.log('in the catch error', err);
		// 			// Error happens when selected company doesn't have legacy account codes or an actual api error.
		// 			this.datadogService.addRumError(err);
		// 			const pnfData = {
		// 				title: 'UDL',
		// 				error: err.name,
		// 				errorMessage: err.message,
		// 				status: err.status,
		// 				url: environment.appUrl,
		// 			};
		// 			this.router.navigate(['404-page'], { queryParamsHandling: 'preserve' , state: pnfData});
		// 			return EMPTY;
		// 		})).subscribe();
	}

	ngOnDestroy() {
		this.datadogService.stopSessionReplayRecording();
	}

	ngAfterViewInit(): void {
		this.cd.detectChanges();
	}

	subscribeToAppFeature() {
		this.store.dispatch(getLocationOptions());
		this.store.dispatch(getAssetOptions());
		this.store.dispatch(getDriverOptions());
		this.store.dispatch(getLocationsList());
		let minutesInterval: any;
		this.store.select(appFeature).pipe(distinctUntilChanged())
			.subscribe(app => {
				this.contextualHelpLoading = app.contextualHelpLoading;
				this.contextualHelpOpen = app.contextualHelpOpen;
				this.contextualHelpHTML = app.contextualHelpHTML;
				this.minutesSinceRefresh = app.minutesSinceRefresh;
				//Todo: remove this check and the dispatcher list when odometer jump report no longer requires the toggle
				//this controls the last remaining dispatcher toggle in the ododmeter jumpm reports,
				// and only displays the toggle when the list is loaded for admin roles
				if (app.isAdmin && this.isFirstLoad) {
					this.isFirstLoad = false;
					this.store.dispatch(getDispatchersList({ reload: false }));
				}
				if (app.userId && app.shouldRefresh) {
					if (minutesInterval) {
						clearInterval(minutesInterval);
						minutesInterval = undefined;
					}

					if (!app.refreshInterval) {
						this.appService.refreshData([
							// new GetHosViolationsLiveStatus(this.route.queryParams),
							// new GetLiveStatus(),
							// disabling availability refresh until further notice
							// new RefreshDriverAvailability(this.route.queryParams),
							incrementRefreshMs({ ms: TwoMinutes }),
						]);

						this.store.dispatch(refreshIntervalSet());
					}
				} else if (app.userId && !app.shouldRefresh && !minutesInterval) {
					this.appService.stopRefreshing();

					minutesInterval = setInterval(() => {
						this.store.dispatch(setMinutesSinceRefresh());
					}, OneMinute);
				}
			});

		this.router.events.pipe(distinctUntilChanged()).subscribe(routerEvent => {
			if (routerEvent instanceof ResolveEnd) {
				this.hideRefreshBar = (
					routerEvent.url.indexOf('/availability') > -1
					|| routerEvent.url.indexOf('/sds') > -1
					|| routerEvent.url.indexOf('/hours') > -1
					|| routerEvent.url.indexOf('/odometer') > -1
					|| routerEvent.url.indexOf('/hos-violations') > -1
				);
			}
		})
	}

	public onSideNavMobileMenuButtonToggled(event) {
		this.sidenavConfig.mobileOpened = event;
	}

	private filterUrlParams(keys, windowContext) {
		let newUrlParams = '?';
		const urlParams = new URLSearchParams(windowContext.location.search);
		_.forEach(keys, (key) => {
			if (urlParams.get(key)) {
				newUrlParams += `&${key}=${urlParams.getAll(key)}`;
			}
		});
		return `${windowContext.location.pathname}${newUrlParams}`;
	}

	private pendoInitialize(user, isAdmin, activeAccount, company) {
		this.ngxPendoService.initialize({
			id: user.id,
			name: `${user.firstName} ${user.lastName}`,
			role: isAdmin ? 'admin' : 'user',
		}, {
			id: activeAccount,
			name: company.name,
		});
	}

	turnOffSidebarState() {
		this.store.dispatch(removeContextualHelp());
	}
}
