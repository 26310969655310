import { Injectable } from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import { Store } from "@ngrx/store";
import { PermissionsService } from "@zonar-ui/auth";
import {forkJoin, iif, Observable, of, throwError, zip} from "rxjs";
import {catchError, delay, filter, finalize, map, switchMap, take} from "rxjs/operators";
import { RootState } from "./app.module";
import { ACTIVE_ACCOUNT_PARAM, LocalStorageService, SELECTED_ENTITY } from "./local-storage.service";
import { getUserDataSuccess } from "./state/app/app.actions";
import { AppService } from "./state/app/app.service";
import {IUserProfile} from "@zonar-ui/auth/lib/models/user-profile.model";
import {IUser} from "@zonar-ui/auth/lib/models/user.model";
import {Company} from "@zonar-ui/sidenav/lib/models/company.model";
import {environment} from "../environments/environment";
import {ICompany} from "@zonar-ui/auth/lib/models/company.model";
import {IUserGroupPolicy} from "@zonar-ui/auth/lib/models/user-group-policy.model";

export const fakeUserProfiles = [
    {
        "created": "2021-12-07T18:20:16.757602",
        "modified": "2021-12-07T18:20:16.757606",
        "managedEntity": {
            "managedSourceId": null,
            "managedSource": null,
            "externallyManaged": false
        },
        "userId": "4e18f943-f3b5-4e3e-b723-acf66d478a81",
        "companyId": null,
        "applicationId": "0c96e82d-da2d-4858-9169-b7729516a960",
        "employeeNumber": null,
        "homeLocationId": null,
        "id": "47541178-4d90-4991-abe3-bc01b1634ab7",
        "divisions": [],
        "status": "ACTIVE",
        "companyEmail": "vijayeta.rangarajan@zonarsystems.com",
        "allDivisions": true,
        "roles": [
            {
                "created": "1984-01-01T00:00:00",
                "modified": "2024-03-05T21:37:29.883266",
                "description": "Zonar Admin User",
                "allowedTenants": [
                    "ALL_COMPANIES",
                    "COMPANY_LIST",
                    "SINGLE_COMPANY",
                    "DIVISION_LIST"
                ],
                "translationId": null,
                "type": "ROLE",
                "productId": "ea4163a4-dedf-4d6c-b464-8a6422519d06",
                "allowManagedUsers": false,
                "permissions": [
                    "hours_of_service:write:udl",
                    "hours_of_service:read:udl",
                    "hours_of_service:read:ndr",
                    "hours_of_service:read:company:companies",
                    "hours_of_service:read:driver_logs",
                    "hours_of_service:write:ndr",
                    "hours_of_service:read:compliance_reports",
                    "hours_of_service:write:auto_d",
                    "hours_of_service:read:map"
                ],
                "apiPermissions": [
                    "hours_of_service:write:auto_d"
                ],
                "id": "725509ee-4f44-4ec0-a24a-d8593d67a7d3",
                "name": "Access Zonar Admin",
                "restricted": false,
                "auditInfo": {
                    "userId": "6c87fa98-82d0-4b4b-b8cd-9aa0a4824460",
                    "clientId": "samlp|Duo|kim.nguyen@zonarsystems.com",
                    "transactionId": null,
                    "groupId": null
                }
            }
        ],
        "auditInfo": null
    }
];

export interface AppRouteData {
    activeAccountSelected: boolean,
    dispatcher?: any,
    activeAccount?: string;
    userProfiles?: IUserProfile[];
    user?: IUser;
    selectedCompany?: Company;
}

@Injectable({
    providedIn: 'root'
})
export class AppResolver implements Resolve<Observable<AppRouteData>> {

    constructor(private appService: AppService,
        private localStorageService: LocalStorageService,
        private permissionsService: PermissionsService,
        private store: Store<RootState>,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppRouteData> {
        const activeAccount = route.queryParamMap.get('active');
        // If active query is not present and existing active is not in localstorage, 
        // then no active parameter message will be shown
        console.log('RESOLVER, activeAccount', activeAccount);

        //Todo: remove this code once we do not want the user to be routed to an empty page with prompt to select active account

        // if (!activeAccount && !this.localStorageService.exists(ACTIVE_ACCOUNT_PARAM)) {
        //     return of({
        //         activeAccountSelected: false,
        //         user: null,
        //         dispatcher: null
        //     });
        // }

        // This will be used to display progress bar on the app-root.component.ts
        this.appService.isAppDataLoading$.next(true);

        const lastAcct = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);

        if (activeAccount) {
            this.localStorageService.set(ACTIVE_ACCOUNT_PARAM, activeAccount);
        }
        //Here add the permission checks for group policy work
        if (activeAccount) {
            // console.log('looking for permission check for zonarUser');
            return this.permissionsService.getIsZonarUser().pipe(
                filter(isZonarUser => !!isZonarUser),
                switchMap((isZonaruser) => {
                    if (isZonaruser) {
                        return this.applyZonarUserLoginLogic(activeAccount);
                    } else {
                        console.log('====> ====> is a zonar user - ', isZonaruser);
                        return this.applyNonZonarUserLoginLogic(activeAccount);
                    }
                }),
                map(appData => {
                    console.log('new app data!!!', appData);
                    this.setUserDetails(appData.user, appData.dispatcher, lastAcct);
                    return appData;
                }),
                finalize(() => {
                    this.appService.isAppDataLoading$.next(false);
                })
            )
        }

        //logic if there is no activeAccount
        console.log('NO ACTIVE ACCOUNT');
        //Todo: review Option 1

        // this.permissionsService.getIsZonarUser().pipe(
        //     switchMap(isZonarUser => {
        //         if (isZonarUser) {
        //             return this.applyZonarUserLoginLogic(activeAccount); //Todo: can we get the company selector show up here?
        //         } else {
        //             return this.getCompanyForNonZonarUser().pipe(
        //                 switchMap((companies) => {
        //                     console.log('.... company', companies);
        //                     //Todo: returns one division here to apply to the activeAccount
        //                     return this.appService.getLegacyAccountCode(companies[0].id).pipe(take(1), map(divisions => {
        //                         return divisions[0];
        //                     }))
        //                 }),
        //             )
        //         }
        //     })
        // ).subscribe(div => {
        //     console.log('IN THE SUBSCRIPTION:', div);
        //     const queryParams: Params = { active: div };
        //     this.router.navigate(
        //         [],
        //         {
        //             relativeTo: this.activatedRoute,
        //             queryParams,
        //             queryParamsHandling: 'merge', // remove to replace all query params by provided
        //         }
        //     ).then(() => {
        //         window.location.reload();
        //     });
        // });

        //Todo:review Option 2\

        // return this.permissionsService.getIsZonarUser().pipe(
        //     switchMap(isZonarUser => {
        //         if (isZonarUser) {
        //             return this.applyZonarUserLoginLogic(activeAccount); //Todo: can we get the company selector show up here?
        //         } else {
        //             console.log('NOT ZONAR USER!!');
        //             return this.getCompanyForNonZonarUser().pipe(
        //                 switchMap((company) => this.determineLoginMode(company[0])),
        //                 switchMap((userPermissionsInfo) => forkJoin([
        //                     of(userPermissionsInfo),
        //                     this.permissionsService.getUser().pipe(take(1), map(users => {
        //                         console.log('GET USER', users);
        //                         return users;
        //                     })),
        //                     this.permissionsService.getCurrentCompanyContext().pipe(take(1), map(company => {
        //                         console.log('get Company context', company);
        //                         return company;
        //                     }))
        //                 ])),
        //                 switchMap(([userPermissionsInfo, user, company]) => forkJoin([
        //                     of(userPermissionsInfo),
        //                     of(user),
        //                     of(company),
        //                     this.appService.getLegacyAccountCode(company.id).pipe(take(1), map(divisions => divisions)),
        //                     this.appService.getDispatcherIdByEmail(user.email).pipe(take(1),map(id => {
        //                         console.log('DISPATCHER ID', id);
        //                         return id;
        //                     })) //Todo: is this needed?
        //                 ])),
        //                 map(([userPermissionsInfo, user, company, div, id]) =>{
        //                     console.log('HERE IS WHAT I HAVE NOW', [userPermissionsInfo, user, company, div]);
        //                    //Todo: we will need to save the divisions somewhere and allow division selector
        //                     const activeAccount = div[0];
        //                     //Todo: find alternative since this is triggering reload
        //                     const queryParams: Params = { active: activeAccount };
        //                     this.router.navigate(
        //                         [],
        //                         {
        //                             relativeTo: this.activatedRoute,
        //                             queryParams,
        //                             queryParamsHandling: 'merge', // remove to replace all query params by provided
        //                         }
        //                     );
        //
        //                     return {
        //                         activeAccountSelected: !!activeAccount,
        //                         activeAccount,
        //                         userProfiles: [{roles: userPermissionsInfo.roles}],
        //                         user,
        //                         selectedCompany: company,
        //                         dispatcher: id //this is because they are are not a zonar user
        //                     }
        //                 }),
        //             )
        //         }
        //
        //     }),
        //     finalize(() => {
        //         console.log('It is finalized!!!!!!!!!!!!!!');
        //         this.appService.isAppDataLoading$.next(false);
        //     })
        // );
    }

    applyZonarUserLoginLogic(acct): Observable<any> {
        return this.permissionsService.getZonarUser().pipe(
            delay(1000),
            filter(x => !!x),
            map(uPolicy => this.getPolicyForApp(uPolicy)), //accepts both policy and profile
            // map(x => this.getPolicyForApp(fakeUserProfiles[0] as IUserProfile)), //change here
            delay(1000),
            switchMap((userPolicyInfo) => forkJoin([
                of(userPolicyInfo),
                this.permissionsService.getUser().pipe(take(1), map(users => users)),
                this.permissionsService.getCurrentCompanyContext().pipe(take(1), map(company => company)), //from sidenav - use this to sideNav to grab the company
                // this.appService.getCompanyByAccount(acct)
            ])),
            switchMap(([zonarUserGroupPolicy, user, company]) => {
                        console.log('... all that is zipped', [zonarUserGroupPolicy, user, company] );
                // let companyForContext = company;
                const userPerm = {
                    roles: zonarUserGroupPolicy?.roles
                };
                // const companyId = companyForContext?.id;
                // this.permissionsService.setCurrentCompanyContextById(companyId);
                // this.changeCompanyService.changeSelectedCompany(companyId);
                // this.appService.getLegacyAccountCode(companyId).subscribe((accounts) => {
                //     const chosenAccount = activeAccount || accounts[0];
                //     const accountMapping = accounts.map((x: string) => ({value: x, title: x}));
                //     this.appService.dispatchActiveAcct(chosenAccount, accountMapping);
                // });
                console.log('++++++++++++++++++++++', {
                    activeAccountSelected: !!acct,
                    activeAccount : acct,
                    userProfiles: [userPerm],
                    user,
                    selectedCompany: company,
                    dispatcher: null //this is because they are are zonar user
                } )
                return of({
                    activeAccountSelected: !!acct,
                    activeAccount : acct,
                    userProfiles: [userPerm],
                    user,
                    selectedCompany: company,
                    dispatcher: null //this is because they are are zonar user
                });
            }),
            catchError(err => {
                console.log('NEw code ERRor', err);
                return err;
            })
        )

        // return this.permissionsService.getUserGroupPolicies().pipe(
        //     filter((x: any[]) => !!x),
        //     switchMap(userPolicy => {
        //         console.log('User policy for zonar user', userPolicy);
        //         //find the policy for the current account/application
        //         return this.getPolicytForApplication(userPolicy[0]);
        //     }),
        //     switchMap((userPolicyInfo) => forkJoin([
        //         of(userPolicyInfo),
        //         this.permissionsService.getUser().pipe(take(1), map(users => users)),
        //         this.permissionsService.getCurrentCompanyContext().pipe(take(1), map(company => {
        //             return company;
        //         }))
        //     ])),
        //     switchMap(([zonarUserGroupPolicy, user, company]) => {
        //         console.log('... all that is zipped', [zonarUserGroupPolicy, user, company] );
        //         const gp = zonarUserGroupPolicy[1];
        //         const userPerm = {
        //             roles: gp[0].roles
        //         };
        //         return of({
        //             activeAccountSelected: !!acct,
        //             activeAccount : acct,
        //             userProfiles: [userPerm],
        //             user,
        //             selectedCompany: company,
        //             dispatcher: null //this is because they are are zonar user
        //         });
        //     }),
        //     catchError(err => {
        //         console.log('OLD WORLD Error', err);
        //         return err;
        //     })
        // );
    }

    private getPolicyForApp(userRole: IUserGroupPolicy | IUserProfile): {roles: any[]; application?: any} {
        let cdPerms;
        if (userRole && 'policy' in userRole && userRole.policy) {
            cdPerms = userRole.policy.grants.filter((c: any) => c.application.id === environment.auth.applicationId)[0];
        } else {
            if ('roles' in userRole) {
                cdPerms = [];
                cdPerms.push({roles: userRole.roles});
            }
        }
        console.log('what  cd perms', cdPerms);
        return cdPerms;
    }

    applyNonZonarUserLoginLogic(activeAccount) {
        return this.getCompanyForNonZonarUser().pipe(
            switchMap(companies => {
                return this.determineLoginMode(companies[0]);
            }),
            switchMap((userPermissionInfo) => forkJoin([
                    of(userPermissionInfo),
                    this.permissionsService.getUser().pipe(take(1), map(users => users)),
                    this.permissionsService.getCurrentCompanyContext().pipe(take(1), map(company => company))
                ])
            ),
            switchMap(([userPermissionsInfo, user, company]) => forkJoin([
                of(userPermissionsInfo),
                of(user),
                of(company),
                this.appService.getDispatcherIdByEmail(user.email).pipe(take(1),map(id => id)) //Todo: is this needed?
            ])),
            map(([userPermissionsInfo, user, company, id]) => {
                console.log('HERE IS WHAT I HAVE NOW', [userPermissionsInfo, user, company, id]);
                return {
                    activeAccountSelected: !!activeAccount,
                    activeAccount,
                    userProfiles: [{roles: userPermissionsInfo['roles'] || []}],
                    user,
                    selectedCompany: company,
                    dispatcher: id //this is because they are are not a zonar user
                }
            }),
        )
    }

    determineLoginMode(company: ICompany) {
        // determine users login mode and retrieve either user profile or group policy
        if (!!company && company.loginMode === 'USER_PROFILE') {
            return this.permissionsService.getUserProfiles().pipe( //used to filter out the correct user profile
                filter((x: any[]) => !!x),
                map(profiles => {
                    return this.getnonZonarUserProfiles(profiles)[0];
                }),
            )
        } else {
            //Todo: need to test this separately with modified response
            return this.permissionsService.getUserGroupPolicyByCompanyId(company.id).pipe(
                filter((x: any) => !!x),
                map(userPolicy => userPolicy),
                switchMap(userPolicy => {
                    return this.getCompanyPolicyForApplication(userPolicy);
                })
            )
        }
    }

    private getCompanyPolicyForApplication(userGroupPolicy: any) {
        const companyId = userGroupPolicy.policy.companyId; //company the policy is tied too
        const cdGrant = userGroupPolicy.policy.grants.filter((c: any) => c.application.id === environment.auth.applicationId);
        const user = userGroupPolicy.user; //user information
        this.permissionsService.setCurrentCompanyContextById(companyId);
        return of([companyId, cdGrant, user]);
    }

    getnonZonarUserProfiles(profiles: IUserProfile[]): IUserProfile[] {
        console.log('//// get NonZonarUserProfiles', profiles);
        let userProfile = profiles.filter((c: any) => c.applicationId === environment.auth.applicationId);
        if (!userProfile || userProfile.length === 0) {
            throw new Error('No userprofile found for user!!!');
        };
        if(userProfile.length > 1) {
            const uniqueCompanyProfiles = [];
            userProfile.forEach(p => {
                if(uniqueCompanyProfiles.filter(u => u.companyId === p.companyId).length === 0) {
                    uniqueCompanyProfiles.push(p);
                }
            });
            userProfile = uniqueCompanyProfiles;
        }
        //Todo:review the check for previous Selection from eldx MR and see if it is necessary here
        console.log('selecting the single profile with matching company Id', userProfile);
        return userProfile;
    }

    getCompanyForNonZonarUser() {
        // determine login mode user has access to based on company
        const index = 0;
        return this.permissionsService.getCompanyLoginMode().pipe(
            filter((x: any[]) => !!x), //filter out null response
            switchMap((companies) => {
                console.log('=====> GET COMPANIES', companies);
                this.permissionsService.setCurrentCompanyContextById(companies[index].id); //sets user profile
                return of(companies);
            }));
    }

    getPolicytForApplication(userGroupPolicy) {
        const companyId = userGroupPolicy.policy.companyId;
        const cDGrant = userGroupPolicy.policy.grants.filter((c: any) => c.application.id === environment.auth.applicationId);
        const user = userGroupPolicy.user;
        this.permissionsService.setCurrentCompanyContextById(companyId); //Todo: look up what does this do again when company is null?
        // console.log('.... getPolicyforApplication', [companyId, cDGrant, user] );
        return of([companyId, cDGrant, user]);
    }

    public setUserDetails(user: any, dispatcher: any, lastAcct: string) {
        const selectedEntity = this.localStorageService.get(SELECTED_ENTITY) || {
            label: ``,
            value: '',
            type: '',
            entityType: '',
        }
        const activeAcct = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);
        // console.log('???????????? check here', dispatcher, activeAcct, lastAcct, selectedEntity);
        if (dispatcher && dispatcher.id && (lastAcct !== activeAcct || !selectedEntity.value)) {
            selectedEntity.value = `${activeAcct}:${dispatcher.id}`;
            selectedEntity.label = `${user.firstName} ${user.lastName}`;
            selectedEntity.type = 'dispatcherId';
            selectedEntity.entityType = 'dispatcherId';
            this.localStorageService.set(SELECTED_ENTITY, selectedEntity);
        } else  if(selectedEntity.value && lastAcct !== activeAcct) {
            // Clear previous selection, let user choose new entity from location/dispatcher on new account selection.
            this.localStorageService.remove(SELECTED_ENTITY);
            selectedEntity.label = '';
            selectedEntity.value = '';
            selectedEntity.type = '';
            selectedEntity.entityType = '';
        }
        const data = {
            userId: user.id,
            displayName: selectedEntity.label,
            companyName: activeAcct,
            divisions: [],
            isAdmin: this.appService.isZonarAdmin(user) || this.appService.isCustomerAdmin(activeAcct),
            selectedEntity,
        }
        this.store.dispatch(getUserDataSuccess(data))
    }
}
